import React from "react";
import { Container, Row } from "reactstrap";
import "./studyabroad.css";
import { Link } from "react-router-dom";
import data from "../../data.json";
import StudyAbroadHomeCard from "../../hooks/StudyAbroadHomeCard";

const StudyAbroad = () => {
  return (
    <section className="py-5" id="study">
        <Container>
        <h1 className="fw-bold">Study Abroad.</h1>
        <p className="fw-bold fs-5">Start your overseas education journey with the best overseas education consultant.</p>
            <Row className="d-flex align-items-center mt-5">
                <StudyAbroadHomeCard />
            </Row>
            <div className="text-center mt-5">
            <Link to='/studyabroadcountries' className="btn-hero" href='#contact' style={{width:'300px'}}>
              <span>View More Countries</span>
            </Link>
            </div>
        </Container>
    </section>
  );
};

export default StudyAbroad;

