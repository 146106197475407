import React from "react";
import { Link } from "react-router-dom";
import CircularProgress from '@mui/material/CircularProgress';

export default class BlogHook extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        error: null,
        isLoaded: false,
        blog: []
      };
    }
    componentDidMount() {
      fetch("https://admin.globalmoon.edu.np/api/blog")
        .then(res => res.json())
        .then(
          (result) => {
            this.setState({
              isLoaded: true,
              blog: result
            });
          },
          (error) => {
            this.setState({
              isLoaded: true,
              error
            });
          }
        )
    }
  
    render() {
      const { error, isLoaded, blog } = this.state;
      if (error) {
        return <div>Error: {error.message}</div>;
      } else if (!isLoaded) {
        return (
          <div className="loading-holder">
            <CircularProgress />
          </div>
        );
      } else {
        return (
            <>
                {blog.map((blog,i)=>(
                    <div className="col-lg-4 col-md-6 col-12 mb-5 blog-card"  key={i}>
                        <div className="img-card-blog m-0">
                        <img src={'https://admin.globalmoon.edu.np/blog/'+blog.blog_image}/>
                        </div>
                        <div className="d-flex justify-content-between py-2">
                        <p className="fw-bold m-0">{blog.blog_title}</p>
                        
                        </div>
                        <p className="lh-sm clamp-5">{blog.desc_one}</p>
                        <Link to={`/blog/${blog?.blog_title}`}>
                        <div className="btn-hero"><span>Read More</span></div>
                        </Link>
                    </div>
                ))}
            </>
        );
      }
    }
  }
