import { Link } from "react-router-dom";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";


import notfound from "../assests/images/notfound.svg";

const NotFound = () => {

  return (
    <>
    <Header></Header>
    <div className="container">
        <div className="row align-items-center">
            <div className="col-md-6 col-12">
            <img src={notfound} className="nf"/>
            </div>
            <div className="col-md-6 col-12">
                <h1 className="fw-bold">Page Not Found</h1>
                <p>Sorry, but the page you are looking for is not found. We have made it easier for you to return to home page. Please click the button below.</p>
                <Link to='/' className="btn-hero">
                    <span>
                 Home
                    </span>
                </Link>
            </div>
        </div>
    </div>
        <Footer/>
    </>
  );
};

export default NotFound;
