import React from "react";
import CircularProgress from '@mui/material/CircularProgress';
import { Link } from "react-router-dom";

export default class StudyAbroadCard extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        error: null,
        isLoaded: false,
        studyabroad: []
      };
    }
    componentDidMount() {
      fetch("https://admin.globalmoon.edu.np/api/studyabroad")
        .then(res => res.json())
        .then(
          (result) => {
            this.setState({
              isLoaded: true,
              studyabroad: result
            });
          },
          (error) => {
            this.setState({
              isLoaded: true,
              error
            });
          }
        )
    }
  
    render() {
      const { error, isLoaded, studyabroad } = this.state;

      if (error) {
        return <div>Error: {error.message}</div>;
      } else if (!isLoaded) {
        return (
          <div className="loading-holder">
            <CircularProgress />
          </div>
        );
      } else {
        return (
          <>
                {
                studyabroad.map((item, index) => (   
                    <div className="col-sm-6 col-md-6 col-lg-4" key={index}>
                        <div className = "card">
                            <img src={'https://admin.globalmoon.edu.np/studyabroad/'+item.country_image} className='card-img'/>
                            <div className="card-content">
                                <div className="card-overlay"></div>
                            <h2 className="card-h2">
                                {item.country_name}
                            </h2>
                            <p className="card-p">
                                {item.title_description}
                            </p>
                            <Link to={`../studyabroad/${item.country_link}`} className="btn-hero card-button" style={{position:"absolute"}}>
                                    <span>Learn More</span>
                            </Link>
                            </div>
                        </div>
                    </div>
                ))
                }
          </>
        );
      }
    }
  }