import React from "react";
import CircularProgress from '@mui/material/CircularProgress';
import { Link } from "react-router-dom";

export default class TestPreparationHeader extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        error: null,
        isLoaded: false,
        testpreparation: []
      };
    }
    componentDidMount() {
      fetch("https://admin.globalmoon.edu.np/api/testpreparation")
        .then(res => res.json())
        .then(
          (result) => {
            this.setState({
              isLoaded: true,
              testpreparation: result
            });
          },
          (error) => {
            this.setState({
              isLoaded: true,
              error
            });
          }
        )
    }
  
    render() {
      const { error, isLoaded, testpreparation } = this.state;

      if (error) {
        return <div>Error: {error.message}</div>;
      } else if (!isLoaded) {
        return (
          <div className="loading-holder">
            <CircularProgress />
          </div>
        );
      } else {
        return (
          <>
            {testpreparation.map((item, index) => (     
                <li className="drop_menu" key={index}>
                  <Link to={`../preparation/${item.test_link}`} className="drop_menu_a">
                    {item.test_name}
                  </Link>
                </li>
              ))}
          </>
        );
      }
    }
  }