import React, { Fragment } from "react";
import Header from "../components/Header/Header"

import Testimonials from "../components/Testimonial/Testimonials";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import ContactUs from "../components/Contact-us/ContactUs";
import Footer from "../components/Footer/Footer";
import usa from "../assests/images/usa.jpg";
import google from "../assests/images/google.png";
import whatsapp from "../assests/images/whatsapp.png";
import skype from "../assests/images/skype.png";
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import { Link } from "react-router-dom";
const tf ={
    width:'95%',
    margin:'10px',
    background:'white',
    "&:active": {
      border:"none",
    },
    borderColor:'white',
    borderRadius:'10px'
  };

const btn = {
  width: "95%",
  margin: "10px",
  background: "#f37224",
  "&:active": {
    border: "white",
  },
  "&:hover": {
    background: "#CF6120",
  },
};
const box = {
  padding: "10px",
  background: "#f0f0f0",
  borderRadius: "20px",
//   maxWidth:"600px"
};
const Schedule = () => {
    const [age, setAge] = React.useState('');

    const handleChange = (event) => {
      setAge(event.target.value);
    };
  return (
    <Fragment>
      <Header />
      <section>
            <div className="cover-bg">
            <div className="container py-5">
            <h1 className="fw-bold text-center">Virtual Meeting</h1>
            <p className="fw-bold fs-5 text-center">Get Counselling at comfort of your home.</p>
            <p className="text-center lh-base">
                Talk to our best counselling team to ensure your brighter future ahead.
            </p>
            </div>
            <img src={usa} className='banner-img' alt="usa"></img>
            </div>
      </section>
      <section>
        <div className="container py-5">
        <h1 className="fw-bold text-center">Choose a Platform</h1>
        <div className="row">
          <div className="col-md-4 col-sm-6 text-center">
          <a href="https://wa.me/+9779856032605" target="_blank">
              <div className="box">
                <img src={whatsapp} alt="" width="200px"/>
              </div>
            </a>
          </div>
          <div className="col-md-4 col-sm-6 text-center">
            <a href="https://meet.google.com/etj-omab-fts" target="_blank">
              <div className="box">
                <img src={google} alt="" width="200px"/>
              </div>
            </a>
          </div>
          <div className="col-md-4 col-sm-6 text-center">
          <a href="skype:9856032605?call" target="_blank">
              <div className="box">
                <img src={skype} alt="" width="200px"/>
              </div>
            </a>
          </div>
        </div>
        </div>

      </section>
      <Testimonials />
      <ContactUs />
      <Footer />
    </Fragment>
  );
};

export default Schedule;
