import React from "react";
import { Link } from "react-router-dom";
import CircularProgress from '@mui/material/CircularProgress';

export default class VideoGalleryHook extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        error: null,
        isLoaded: false,
        videogallery: []
      };
    }
    componentDidMount() {
      fetch("https://admin.globalmoon.edu.np/api/videogallery")
        .then(res => res.json())
        .then(
          (result) => {
            this.setState({
              isLoaded: true,
              videogallery: result
            });
          },
          (error) => {
            this.setState({
              isLoaded: true,
              error
            });
          }
        )
    }
  
    render() {
      const { error, isLoaded, videogallery } = this.state;
      if (error) {
        return <div>Error: {error.message}</div>;
      } else if (!isLoaded) {
        return (
          <div className="loading-holder">
            <CircularProgress />
          </div>
        );
      } else {
        return (
            <>
            {videogallery.map((blog,i)=>(
              <div className="col-lg-4 col-md-6 col-12" key={i}>
                  <div className="img-card-blog-video">
                    <iframe src={blog.video_url} title={blog.title} frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                  </div>
                  <h4>{blog.title}</h4>
              </div>
            ))}
            </>
        );
      }
    }
  }