import React from "react";
import CircularProgress from '@mui/material/CircularProgress';
import { Link } from "react-router-dom";

export default class TestPreparationCard extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        error: null,
        isLoaded: false,
        test: []
      };
    }
    componentDidMount() {
      fetch("https://admin.globalmoon.edu.np/api/testpreparation")
        .then(res => res.json())
        .then(
          (result) => {
            this.setState({
              isLoaded: true,
              test: result
            });
          },
          (error) => {
            this.setState({
              isLoaded: true,
              error
            });
          }
        )
    }
  
    render() {
      const { error, isLoaded, test } = this.state;

      if (error) {
        return <div>Error: {error.message}</div>;
      } else if (!isLoaded) {
        return (
          <div className="loading-holder">
            <CircularProgress />
          </div>
        );
      } else {
        return (
          <>
              {
                test.map((item, index) => (             
                  <div className="col-sm-6 col-md-6 col-lg-3 my-3" key={index}>
                    <div className="ag-courses_item">
                      <Link to={`../preparation/${item.test_link}`} className="ag-courses-item_link">
                        <div className="ag-courses-item_bg"></div>
                        <div className="ag-courses-item_title">
                          {item.test_name}.
                        </div>
                        <div className="ag-courses-item_date-box">
                          <span className="ag-courses-item_date">
                            Learn More
                          </span>
                        </div>
                      </Link>
                    </div>
                  </div>
                ))
              }  
          </>
        );
      }
    }
  }