import React, { useRef } from "react";
import { Container } from "reactstrap";
import Logo from "../../assests/images/Logo.png";
import "./header.css";
import { Link } from "react-router-dom";
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import YouTubeIcon from "@mui/icons-material/YouTube";
// import PhoneIcon from "@mui/icons-material/Phone";
import RoomIcon from "@mui/icons-material/Room";
import EmailIcon from "@mui/icons-material/Email";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import VideocamIcon from '@mui/icons-material/Videocam';
import data from "../../data.json";
import StudyAbroadHeader from "../../hooks/StudyAbroadHeader";
import LinksHook from "../../hooks/Links";
import TestPreparationHeader from "../../hooks/TestPreparationHeader";

const Header = () => {
  const menuRef = useRef();

  const menuToggle = () => menuRef.current.classList.toggle("active__menu");

  return (
    <header className="header">
    <nav className="navbar navbar-expand-md  py-3 d-flex justify-content-between">
      <Container>
        <div className="header-logo">
          <Link to='/'>
          <img src={Logo} alt="Logo" height={60} />
          </Link>
        </div>
        <div className="nav align-items-center gap-5">
          <div className="nav__menu" ref={menuRef} onClick={menuToggle}>
            <ul className="nav__list">
              <li className="nav__item">
                <Link to="/about">About Us</Link>
              </li>
              <li className="nav__item">
                <Link to='/studyabroadcountries'>Study Abroad 
                <ArrowDropDownIcon></ArrowDropDownIcon>
                </Link>
                <div className="dropdown">
                  <div className="row">
                    <div className="col-12 px-4">
                      <ul className="m-0 p-0">
                        <StudyAbroadHeader />
                        <li className="drop_menu">
                          <Link to='/studyabroadcountries' className="drop_menu_a">
                            More...
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>  
                </div>
              </li>
              <li className="nav__item">
                <a href="#test">Test Preparation
                <ArrowDropDownIcon></ArrowDropDownIcon></a>
              <div className="dropdown" style={{transform: 'translate(-20px, 0px)'}}>
                  <div className="row">
                    <div className="col-12 px-4">
                      <ul className="m-0 p-0">
                        <TestPreparationHeader />
                      </ul>
                    </div>
                  </div>
                </div>
              </li>
              <li className="nav__item">
                <a href="#">Important Links
                <ArrowDropDownIcon></ArrowDropDownIcon></a>
              <div className="dropdown" style={{transform: 'translate(-20px, 0px)'}}>
                  <div className="row">
                    <div className="col-12 px-4">
                      <ul className="m-0 p-0">
                        <LinksHook />
                      </ul>
                    </div>
                  </div>
                </div>
              </li>
              <li className="nav__item">
                <a href="#test">Media
                <ArrowDropDownIcon></ArrowDropDownIcon></a>
              <div className="dropdown" style={{transform: 'translate(-20px, 0px)'}}>
                  <div className="row">
                    <div className="col-12 px-4">
                      <ul className="m-0 p-0">
                        <li className="drop_menu">
                          <Link to='/gallery' className="drop_menu_a">Photo Gallery</Link>
                        </li>
                        <li className="drop_menu">
                          <Link to='/video_gallery' className="drop_menu_a">Video Gallery</Link>
                        </li>
                        <li className="drop_menu">
                          <Link to='/blog' className="drop_menu_a">Blog</Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </li>     

              <li className="nav__item nav-cta">
                <a href="https://admin.globalmoon.edu.np/login" target="_blank">Login</a>
              </li>
              
              <li className="nav__item nav-cta">
                <a href="#contact">Contact Us</a>
              </li>

              
              <li className="nav__item nav-cta" style={{background: "#f37224", padding: "10px", borderRadius:"30px"}}>
                <Link to="/virtual-councelling" style={{color: "#FFFFFF",display:"flex",alignItems:"center"}}>
                  <VideocamIcon></VideocamIcon>
                  Virtual Meeting</Link>
              </li>

            </ul>
          </div>
        </div>
        <div className="mobile__menu">
          <span>
            <i className="ri-menu-line" onClick={menuToggle}></i>
          </span>
        </div>
      </Container>
    </nav>
      <Container>
        <hr className="m-0 c-sec" />
      </Container>
      <nav className="navbar navbar-expand-md p-0 info-nav bg-sec">
        <div className="container d-flex">
          <a href="mailto:info@globalmoon.edu.np" className="m-2">
            <EmailIcon />
            info@globalmoon.edu.np
          </a>
          <div className="social-links">
            <a href="https://twitter.com/globalmoon_edu" target="_blank">
              <TwitterIcon className="m-2" />
            </a>
            <a href="https://www.facebook.com/globalmoon.education" target="_blank">
              <FacebookIcon className="m-2" />
            </a>
            {/* <a href="https://www.linkedin.com" target="_blank">
              <LinkedInIcon className="m-2" />
            </a> */}
            <a href="https://wa.me/+9779856032605" target="_blank">
              <WhatsAppIcon className="m-2" />
            </a>
            <a href="https://www.instagram.com/globalmoon.consultancy" target="_blank">
              <InstagramIcon className="m-2" />
            </a>
            <a href="https://www.youtube.com/channel/UCT_vxAIJ6ErTJbL64AEPw6Q" target="_blank">
              <YouTubeIcon className="m-2" />
            </a>
            <a href="https://www.tiktok.com/@global.moon.consultancy" target="_blank">
              <img src="https://cdn-icons-png.flaticon.com/128/5969/5969008.png" width="24" className="mx-2 png-white"/>
            </a>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Header;
