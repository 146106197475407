import React from "react";
import CircularProgress from '@mui/material/CircularProgress';
export default class TestPreparation extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        error: null,
        isLoaded: false,
        test: []
      };
    }
    componentDidMount() {
      fetch("https://admin.globalmoon.edu.np/api/testpreparation")
        .then(res => res.json())
        .then(
          (result) => {
            this.setState({
              isLoaded: true,
              test: result
            });
          },
          (error) => {
            this.setState({
              isLoaded: true,
              error
            });
          }
        )
    }
  
    render() {
      const { error, isLoaded, test } = this.state;
      const link=this.props.link;
      const filtered = test.filter(p => p.test_link === link);
      if (error) {
        return <div>Error: {error.message}</div>;
      } else if (!isLoaded) {
        return (
          <div className="loading-holder">
            <CircularProgress />
          </div>
        );
      } else {
        return (
          <>
                <section>
                <div className="cover-bg">
                <div className="container py-5">
                <h1 className="fw-bold text-center">{filtered[0].test_title_one}</h1>
                <p className="fw-bold fs-5 text-center">{filtered[0].test_tagline}</p>
                <p className="text-center lh-base">{filtered[0].test_desc_one}</p>
                </div>
                {/* <img src={usa} className='banner-img' alt="usa"></img> */}
                </div>
                <div className="container">  
                    <div className="row  my-5 d-flex align-items-center">
                        <div className="col-md-6 col-sm-12 relative text-center">
                        <img className="side-img" src={'https://admin.globalmoon.edu.np/testpreparation/'+filtered[0].test_title_two_image} alt="usa"></img>
                        </div>
                        <div className="col-md-6 col-sm-12">
                        <h1 className="fw-bold">{filtered[0].test_title_two}</h1>
                        <p className="lh-base">{filtered[0].test_title_two_desc}</p>
                        <a className="btn-hero" href={filtered[0].test_title_two_link} style={{width:'300px'}}>
                        <span>Contact Us for {filtered[0].test_name} preparation</span>
                        </a>
                        </div>
                    </div>
                    <div className="row  my-5 d-flex align-items-center ">
                        <div className="col-md-6 col-sm-12">
                        <h1 className="fw-bold">{filtered[0].test_title_three}</h1>
                        <p className="lh-base">{filtered[0].test_title_three_desc}</p>
                        <a className="btn-hero" href={filtered[0].test_title_three_link} style={{width:'300px'}}>
                        <span>Contact Us to Study in Japan</span>
                        </a>
                        </div>
                        <div className="col-md-6 col-sm-12 relative text-center">
                        <img className="side-img" src={'https://admin.globalmoon.edu.np/testpreparation/'+filtered[0].test_title_three_image} alt="usa"></img>
                        </div>
                    </div>
                    <div className="row  my-5 d-flex align-items-center">
                        <div className="col-md-6 col-sm-12 relative text-center">
                        <img className="side-img" src={'https://admin.globalmoon.edu.np/testpreparation/'+filtered[0].test_title_four_image} alt="usa"></img>
                        </div>
                        <div className="col-md-6 col-sm-12">
                        <h1 className="fw-bold">{filtered[0].test_title_four}</h1>
                        <p className="lh-base">{filtered[0].test_title_four_desc}</p>
                        <a className="btn-hero" href={filtered[0].test_title_four_Link} style={{width:'300px'}}>
                        <span>Contact Us to Study in Japan</span>
                        </a>
                        </div>
                    </div>
                    <div className="row  my-5 d-flex align-items-center ">
                      <div dangerouslySetInnerHTML={{ __html: filtered[0].cost_desc }}></div>
                    </div>
                
                </div>

                </section>
          </>
        );
      }
    }
  }