import React from "react";
import { Container, Row, Col, ListGroup, ListGroupItem } from "reactstrap";
import revert from "../../assests/images/globalfooter.png";
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import { WhatsApp } from "@mui/icons-material";
import YouTube from "@mui/icons-material/YouTube";
import data from "../../data.json";
import "./footer.css";
import { Link } from "react-router-dom";
import StudyAbroadFooter from "../../hooks/StudyAbroadFooter";
import TestPreparationFooter from "../../hooks/TestPreparationFooter";




const footerQuickLinks = [
  {
    display: "Home",
    url: "/",
  },
  {
    display: "About US",
    url: "/about",
  },

  {
    display: "Study Abroad",
    url: "/studyabroadcountries",
  },

  {
    display: "Virtual Meeting",
    url: "/virtual-councelling",
  },

  {
    display: "Study Abroad",
    url: "/tudyabroadcountries",
  },
];



const Footer = () => {
  return (
    <footer className="footer">
      <Container>
        <Row>
          <Col lg="3" md="6" className="mb-4 relative">
            <div className="vr-custom"></div>
            <div className="logo pb-3">
                <img src={revert} alt="Logo" height={60}/>
                <p className="m-0 fw-bold">Global Moon Consultancy Pvt. Ltd.</p>
                <p className="m-0">Putalisadak, Kathmandu, Nepal</p>
                <p className="m-0">Phone:  01-4168319</p>
                <p className="m-0">info@globalmoon.edu.np, globalmoon.edu@gmail.com</p>
            </div>
            <div className="follows">
              <span>
                {" "}
                <a href="https://twitter.com/globalmoon_edu" target="_blank">
                  <TwitterIcon/>
                </a>
              </span>

              <span>
                {" "}
                <a href="https://www.facebook.com/globalmoon.education" target="_blank">
                  <FacebookIcon/>
                </a>
              </span>

              {/* <span>
                {" "}
                <a href="facebook.com">
                  <LinkedInIcon/>
                </a>
              </span> */}

              <span>
                {" "}
                <a href="https://wa.me/+9779856032605">
                  <WhatsApp/>
                </a>
              </span>

              <span>
                {" "}
                <a href="https://www.instagram.com/globalmoon.consultancy" target="_blank">
                  <InstagramIcon/>
                </a>
              </span>

              <span>
                {" "}
                <a href="https://www.youtube.com/channel/UCT_vxAIJ6ErTJbL64AEPw6Q" target="_blank">
                  <YouTube/>
                </a>
              </span>

              <span>
                {" "}
                  <a href="https://www.tiktok.com/@global.moon.consultancy" target="_blank">
                    <img src="https://cdn-icons-png.flaticon.com/128/5969/5969008.png" width="24" className="png-white"/>
                  </a>
              </span>
            </div>
          </Col>
          <Col lg="3" md="6" className="mb-4 relative">
            <div className="vr-custom vr-custom-2"></div>
            <h6 className="fw-bold">Explore</h6>
            <ListGroup className="link__list">
              {footerQuickLinks.map((item, index) => (
                <ListGroupItem key={index} className="border-0 ps-0 link__item">
                  {" "}
                  <Link to={item.url} key={index}>{item.display}</Link>
                </ListGroupItem>
              ))}
            </ListGroup>
          </Col>

          <Col lg="3" md="6" className="mb-4 relative">
            <div className="vr-custom"></div>
            <h6 className="fw-bold">Study Abroad</h6>
            <ListGroup className="link__list">
                <StudyAbroadFooter />
            </ListGroup>
          </Col>

          <Col lg="3" md="6">
            <div className="vr-custom"></div>
            <h6 className="fw-bold">Test Preparation</h6>
            <ListGroup className="link__list">
                <TestPreparationFooter />
            </ListGroup>
          </Col>
        </Row>
        </Container>
        <div className="copyright d-flex justify-content-center bg-sec pt-4">
          <p className="fc-white m-0">
          Copyright © 2023 Global Moon Consultancy Pvt. Ltd.
            </p>
          </div>
        <div className="copyright d-flex justify-content-center bg-sec pb-4 ">
          <i className="fc-white">
            "A Complete Solution For Abroad Study"
          </i>
        </div>          
    </footer>
  );
};

export default Footer;
