import React, { Fragment } from "react";
import Header from "../components/Header/Header"
import Footer from "../components/Footer/Footer";
import usa from "../assests/images/usa.jpg";
import data from "../data.json";
import { Link, useParams } from "react-router-dom";
import ClearIcon from '@mui/icons-material/Clear';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import zIndex from "@mui/material/styles/zIndex";
import ModelGallery from "./modelgallery";
import GalleryPageHook from "../hooks/GalleryPageHook";


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "100%",
  height:"100%",
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 1,
};
const zi ={
  zIndex:"99999999999999999999999",
}
const GalleryPage = () => {
  const { link } = useParams();
  return (
    <div className="gal-page">
    <GalleryPageHook link={link}></GalleryPageHook>
    </div>
  );
};

export default GalleryPage;
