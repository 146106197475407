import React from "react";
import usa from "../assests/images/usa.jpg";
// import aboutone from "../assests/images/aboutone.png";
import blobanimation from "../assests/images/blobanimation.svg";
// import { useParams } from "react-router-dom";
import { useParams } from "react-router-dom";


import Header from "../components/Header/Header"
import Testimonials from "../components/Testimonial/Testimonials";
import ContactUs from "../components/Contact-us/ContactUs";
import Footer from "../components/Footer/Footer";
import TestPreparation from "../hooks/TestPreparation";
const countries = [  
  {
    course: "Ielts",
    maintitle: "What is IELTS?",
    maintitletagline:"IELTS arcnoym is International English Language Testing System.",
    maintitlecontent: "The International English Language Testing System (IELTS) is a standardized and widely recognized English language assessment test for international education and global migration. It evaluates the English language proficiency of non-native English speakers, who wish to study in or migrate to countries, where English is the language of communication.",
    
    titleone:"Why IELTS is important?",
    titleonecontent:"IELTS is one of the most trusted English language tests across the globe with more than 10,000 organizations recognizing it internationally. The IELTS certification is recognized by the UK, USA, Australia, Canada, New Zealand, Singapore, Europe and 140 more countries across the globe. The certification is accepted by academic institutions, companies, professional bodies and government organizations. The test is considered the standard for English language testing and their certification is recognized for both academic and immigration purposes. This test assesses the English language skills of a candidate by testing their reading, writing, listening and speaking skills. Tests are available throughout the year in more than 1600 centers in over 140 countries. With IELTS scores, you can study in some of the best universities in Australia, top universities in Canada, USA, UK and New Zealand.",
    
    popcourses:"Poular Courses to study in Japan.",
    popcourses_content:"Since our service and motto is parallel to your ambition, we are never shadowed away from you. Transparency is the primary principle of our business that ascertains the future of our institution as well. We will be concerned and contemplating about you from the first day of your visit to us until the date to your degree achievement. From the issues like abroad living accommodation, banking and insurances, we deal your stepwise process and enlighten you with the best suitable option with procedure. Our team is readily available in any complications you face during the abroad application extent round the hour.",
    
    ieltsrequirements:"HOW TO REGISTER FOR THE IELTS EXAMINATION?",
    visarequirements_content:"Since our service and motto is parallel to your ambition, we are never shadowed away from you. Transparency is the primary principle of our business that ascertains the future of our institution as well. We will be concerned and contemplating about you from the first day of your visit to us until the date to your degree achievement. From the issues like abroad living accommodation, banking and insurances, we deal your stepwise process and enlighten you with the best suitable option with procedure. Our team is readily available in any complications you face during the abroad application extent round the hour.",
    
    cost:"Total Education Cost.",
    cost_content:"Since our service and motto is parallel to your ambition, we are never shadowed away from you. Transparency is the primary principle of our business that ascertains the future of our institution as well. We will be concerned and contemplating about you from the first day of your visit to us until the date to your degree achievement. From the issues like abroad living accommodation, banking and insurances, we deal your stepwise process and enlighten you with the best suitable option with procedure. Our team is readily available in any complications you face during the abroad application extent round the hour.",
    edu_cost_table:[
      {
        "Vocal Education and Training":"$4,000 to $22,000",
        "Undergraduate Bachelor Degree":"$20,000 to $45,000",
        "Postgraduate Masters Degree":"$22,000 to $50,000",
        "Doctoral Degree":"$18,000 to $42,000",
      }
    ],
    accommodation:[
      {
        "Hostels and Guesthouses":"$90 to $150",
        "Shared Rental":"$95 to $215",
      }
    ],
    living_expenses:[
      {
        "Hostels and Guesthouses":"$90 to $150",
        "Shared Rental":"$95 to $215",
      }
    ],
    img:"",
  },
];

const Preparation = () => {
  const { link } = useParams();

  return (
    <>
        <Header />
        <TestPreparation link={link} />
        <Testimonials />
        <ContactUs />
        <Footer />
    </>
  );
};

export default Preparation;
