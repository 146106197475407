import React, { Fragment } from "react";
import Header from "../components/Header/Header"
import Footer from "../components/Footer/Footer";
import usa from "../assests/images/usa.jpg";
import data from "../data.json";
import { Link, useParams } from "react-router-dom";
import ClearIcon from '@mui/icons-material/Clear';
import CircularProgress from '@mui/material/CircularProgress';


import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import zIndex from "@mui/material/styles/zIndex";
import GalleryPostHook from "./GalleryPostHook";


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "100%",
  height:"100%",
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 1,
};
const zi ={
  zIndex:"99999999999999999999999",
}
export default class GalleryPageHook extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        error: null,
        isLoaded: false,
        gallery: []
      };
    }
    componentDidMount() {
      fetch("https://admin.globalmoon.edu.np/api/gallery")
        .then(res => res.json())
        .then(
          (result) => {
            this.setState({
              isLoaded: true,
              gallery: result
            });
          },
          (error) => {
            this.setState({
              isLoaded: true,
              error
            });
          }
        )
    }
  
    render() {
      // const [open, setOpen] = React.useState(false);
      // const handleOpen = () => setOpen(true);
      // const handleClose = () => setOpen(false);
      
      const { error, isLoaded, gallery } = this.state;
      const link=this.props.link;
      const filteredEvent = gallery.filter(p => p.event_name == link);
      if (error) {
        return <div>Error: {error.message}</div>;
      } else if (!isLoaded) {
        return (
          <div className="loading-holder">
            <CircularProgress />
          </div>
        );
      } else {
        return (
          <>
            <Fragment>
            <Header />
            <section>   
            <div className="cover-bg">
            <div className="container py-5">
            <h1 className="fw-bold text-center">Gallery</h1>
                <p className="fw-bold fs-5 text-center">{filteredEvent[0].event_name}</p>
            </div>
            <img src={usa} className='banner-img' alt="usa"></img>
            </div>
            </section>
            <section>
            <div className="container py-5">
                <div className="row">
                  <GalleryPostHook galid={filteredEvent[0].id} />
                </div>
            </div>
            </section>
            {/* <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={zi}
            >
            <Box sx={style}>
                    <ModelGallery />
                    <ClearIcon className="cross" onClick={handleClose} />
            </Box>
            </Modal> */}
            <Footer />
            </Fragment>
          </>
        );
      }
    }
  }