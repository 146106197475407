import React, { Fragment } from "react";
import Header from "../components/Header/Header"
import Footer from "../components/Footer/Footer";
import usa from "../assests/images/usa.jpg";
import data from "../data.json";
import { Link } from "react-router-dom";

import GalleryHook from "../hooks/GalleryHook";

const Gallery = () => {
  return (
    <Fragment>
      <Header />
      <section>   
        <div className="cover-bg">
        <div className="container py-5">
        <h1 className="fw-bold text-center">Gallery</h1>
          <p className="fw-bold fs-5 text-center">Some Glimpse of Global Moon Education</p>
        </div>
        <img src={usa} className='banner-img' alt="usa"></img>
        </div>
      </section>
      <section>
        <div className="container py-5">
          <div className="row">
            <GalleryHook />
          </div>
        </div>
      </section>
      <Footer />
    </Fragment>
  );
};

export default Gallery;
