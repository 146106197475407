import React from "react";
import CircularProgress from '@mui/material/CircularProgress';
import Slider from "react-slick";


export default class Testinomial extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        error: null,
        isLoaded: false,
        testinomial: []
      };
    }
    componentDidMount() {
      fetch("https://admin.globalmoon.edu.np/api/testinomial")
        .then(res => res.json())
        .then(
          (result) => {
            this.setState({
              isLoaded: true,
              testinomial: result
            });
          },
          (error) => {
            this.setState({
              isLoaded: true,
              error
            });
          }
        )
    }
  
    render() {
      const { error, isLoaded, testinomial } = this.state;
      if (error) {
        return <div>Error: {error.message}</div>;
      } else if (!isLoaded) {
        return (
            <div className="loading-holder">
              <CircularProgress />
            </div>
          );
      } else {
          const settings = {
            infinite: true,
            dots: true,
            speed: 500,
            slidesToShow: 2,
            autoplay: true,
            autoplaySpeed: 4000,
            slidesToScroll: 1,
            responsive: [
              {
                breakpoint: 768,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,
                }
              }
            ]
          };
        return (
          <>
          <Slider {...settings}>
            {testinomial.map((item, index) => (
                        <div className="single__testimonial" key={index}>
                        <img src={'https://admin.globalmoon.edu.np/testinomial/'+item.client_image}></img>
                          <p className="lh-base">{item.comment}</p>
                          <div className="student__info mt-4">
                            <h6 className="fw-bold">{item.client_name}</h6>
                            <p>{item.client_address}</p>
                          </div>
                      </div>
            ))}
            </Slider>

          </>
        );
      }
    }
  }