import React, { Fragment } from "react";
import Header from "../components/Header/Header"
import Footer from "../components/Footer/Footer";
import usa from "../assests/images/usa.jpg";
import CircularProgress from '@mui/material/CircularProgress';




const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "100%",
  height:"100%",
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 1,
};
const zi ={
  zIndex:"99999999999999999999999",
}
export default class BlogPageHook extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        error: null,
        isLoaded: false,
        blog: []
      };
    }
    componentDidMount() {
      fetch("https://admin.globalmoon.edu.np/api/blog")
        .then(res => res.json())
        .then(
          (result) => {
            this.setState({
              isLoaded: true,
              blog: result
            });
          },
          (error) => {
            this.setState({
              isLoaded: true,
              error
            });
          }
        )
    }
  
    render() {
      
      const { error, isLoaded, blog } = this.state;
      const link=this.props.link;
      const filteredBlog = blog.filter(p => p.blog_title == link);
      if (error) {
        return <div>Error: {error.message}</div>;
      } else if (!isLoaded) {
        return (
          <div className="loading-holder">
            <CircularProgress />
          </div>
        );
      } else {
        return (
          <>
            <Fragment>
                <Header />
                <section>   
                    <div className="cover-bg">
                    <div className="container py-5">
                    <h1 className="fw-bold text-center">Blog</h1>
                    <p className="fw-bold fs-5 text-center">Home / Blog / {filteredBlog[0].blog_title}</p>
                    </div>
                    <img src={usa} className='banner-img' alt="usa"></img>
                    </div>
                </section>
                <section>
                <div className="container py-6">
                    <img src={'https://admin.globalmoon.edu.np/blog/'+filteredBlog[0].blog_image} />
                    <h2>{filteredBlog[0].blog_title}</h2>
                    <div className="d-flex justify-content-between py-2">
                      <div dangerouslySetInnerHTML={{ __html: filteredBlog[0].blog_description }}></div>
                    </div>
                </div>
                </section>
                <Footer />
            </Fragment>
          </>
        );
      }
    }
  }