import React from "react";
import "./testimonial.css";
import { Container } from "reactstrap";
import Slider from "react-slick";
import aboutone from "../../assests/images/aboutone.png";

import airplane from "../../assests/images/airplane.png";

import data from "../../data.json";
import Testinomial from "../../hooks/Testinomial";

const Testimonials = () => {
  const settings = {
    infinite: true,
    dots: true,
    speed: 500,
    slidesToShow: 2,
    autoplay: true,
    autoplaySpeed: 4000,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      }
    ]
  };
  return (
    <section className="py-5 global-primary relative">
      <div className="icon-bg">
        <img src={airplane} alt="airplane"></img>
      </div>
      <Container>
      <h1 className="fw-bold">Testimonials.</h1>
        <p className="fw-bold fs-5">Hear From people who got here before you.</p>
            <div className="testimonial__wrapper d-flex justify-content-between align-items-center ">
              {/* <div className="testimonial__img w-50">
                <img src={img} alt="" className="testimonial-img" />
              </div> */}

              <div className="testimonial__content w-100">
                  <Testinomial />
              </div>
            </div>
      </Container>
    </section>
  );
};

export default Testimonials;
