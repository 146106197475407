import React from "react";
import usa from "../assests/images/usa.jpg";
// import aboutone from "../assests/images/aboutone.png";
import blobanimation from "../assests/images/blobanimation.svg";
import { useParams } from "react-router-dom";

import Header from "../components/Header/Header"
import Testimonials from "../components/Testimonial/Testimonials";
import ContactUs from "../components/Contact-us/ContactUs";
import Footer from "../components/Footer/Footer";
import StudyAbroad from "../hooks/StudyAbroad";


const Studyin = () => {
  const { link } = useParams();

  return (
    <>
        <Header />
        <StudyAbroad link={link} />
        <Testimonials />
        <ContactUs />
        <Footer />
    </>
  );
};

export default Studyin;
