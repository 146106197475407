import React from "react";
import CircularProgress from '@mui/material/CircularProgress';

export default class GalleryPostHook extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        error: null,
        isLoaded: false,
        gallerypost: []
      };
    }
    componentDidMount() {
      fetch("https://admin.globalmoon.edu.np/api/gallerypost")
        .then(res => res.json())
        .then(
          (result) => {
            this.setState({
              isLoaded: true,
              gallerypost: result
            });
          },
          (error) => {
            this.setState({
              isLoaded: true,
              error
            });
          }
        )
    }
  
    render() {
      const { error, isLoaded, gallerypost } = this.state;
      const galid=this.props.galid;
      const filteredEvent = gallerypost.filter(p => p.galleries_id === galid);

      
      const gallerypostimage = filteredEvent.length > 0 ? filteredEvent[0].gallerypostimage.split('|') : [];

      if (error) {
        return <div>Error: {error.message}</div>;
      } else if (!isLoaded) {
        return (
          <div className="loading-holder">
            <CircularProgress />
          </div>
        );
      } else {
        return (
            <>
                {gallerypostimage.map((image,i)=>(
                    <div className="col-lg-3 col-md-4 col-6" key={i}>
                        <div className="img-card">
                        {/* <div className="img-card" onClick={handleOpen}> */}
                        <img className="side-img" src={'https://admin.globalmoon.edu.np/galleryevent/gallerypost/'+image} alt="usa"></img>
                        </div>
                    </div>
                ))}
            </>
        );
      }
    }
  }