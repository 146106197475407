import React from "react";
import { Container, Row } from "reactstrap";
import Header from "../components/Header/Header";


import Testimonials from "../components/Testimonial/Testimonials";

import ContactUs from "../components/Contact-us/ContactUs";
import Footer from "../components/Footer/Footer";

import data from "../data.json";
import StudyAbroadCard from "../hooks/StudyAbroadCard";

const StudyAbroadCountries = () => {
  return (
    <>
    <Header/>
    <section className="py-5" id="study">
        <Container>
        <h1 className="fw-bold">Study Abroad.</h1>
        <p className="fw-bold fs-5">Start your overseas education journey with the best overseas education consultant.</p>
            <Row className="d-flex align-items-center mt-5">
                <StudyAbroadCard />

            </Row>
        </Container>
    </section>
    <Testimonials />
    <ContactUs/>
    <Footer/>
    </>

  );
};

export default StudyAbroadCountries;

