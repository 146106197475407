import React from "react";
import CircularProgress from '@mui/material/CircularProgress';
export default class StudyAbroad extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        error: null,
        isLoaded: false,
        studyabroad: []
      };
    }
    componentDidMount() {
      fetch("https://admin.globalmoon.edu.np/api/studyabroad")
        .then(res => res.json())
        .then(
          (result) => {
            this.setState({
              isLoaded: true,
              studyabroad: result
            });
          },
          (error) => {
            this.setState({
              isLoaded: true,
              error
            });
          }
        )
    }
  
    render() {
      const { error, isLoaded, studyabroad } = this.state;
      const link=this.props.link;
      const filtered = studyabroad.filter(p => p.country_link === link);

      if (error) {
        return <div>Error: {error.message}</div>;
      } else if (!isLoaded) {
        return (
          <div className="loading-holder">
            <CircularProgress />
          </div>
        );
      } else {
        return (
          <>
                <section>
                <div className="cover-bg">
                <div className="container py-5">
                <h1 className="fw-bold text-center">{filtered[0].title_one}</h1>
                <p className="fw-bold fs-5 text-center">{filtered[0].title_tagline}</p>
                <p className="text-center lh-base">{filtered[0].title_description}</p>
                </div>
                {/* <img src={usa} className='banner-img' alt="usa"></img> */}
                </div>
                <div className="container">  
                    <div className="row  my-5 d-flex align-items-center">
                        <div className="col-md-6 col-sm-12 relative text-center">
                        <img className="side-img" src={'https://admin.globalmoon.edu.np/studyabroad/'+filtered[0].title_two_image} alt="usa"></img>
                        </div>
                        <div className="col-md-6 col-sm-12">
                        <h1 className="fw-bold">{filtered[0].title_two}</h1>
                        <p className="lh-base">{filtered[0].title_two_description}</p>
                        <a className="btn-hero" href={filtered[0].title_two_link} style={{width:'300px'}}>
                        <span>Learn More</span>
                        </a>
                        </div>
                    </div>
                    <div className="row  my-5 d-flex align-items-center ">
                        <div className="col-md-6 col-sm-12">
                        <h1 className="fw-bold">{filtered[0].title_three}</h1>
                        <p className="lh-base">{filtered[0].title_three_description}</p>
                        <a className="btn-hero" href={filtered[0].title_three_link} style={{width:'300px'}}>
                        <span>Learn More</span>
                        </a>
                        </div>
                        <div className="col-md-6 col-sm-12 relative text-center">
                        <img className="side-img" src={'https://admin.globalmoon.edu.np/studyabroad/'+filtered[0].title_three_image} alt="usa"></img>
                        </div>
                    </div>
                    <div className="row  my-5 d-flex align-items-center">
                        <div className="col-md-6 col-sm-12 relative text-center">
                        <img className="side-img" src={'https://admin.globalmoon.edu.np/studyabroad/'+filtered[0].title_four_image} alt="usa"></img>
                        </div>
                        <div className="col-md-6 col-sm-12">
                        <h1 className="fw-bold">{filtered[0].title_four}</h1>
                        <p className="lh-base">{filtered[0].title_four_description}</p>
                        <a className="btn-hero" href={filtered[0].title_four_Link} style={{width:'300px'}}>
                        <span>Learn More</span>
                        </a>
                        </div>
                    </div>
                    <div className="row  my-5 d-flex align-items-center ">
                      <div dangerouslySetInnerHTML={{ __html: filtered[0].cost_description }}></div>
                        {/* <table className="table table-striped table-bordered mx-3">
                        <thead className="global-primary">
                            <tr>
                            <th scope="col">S.N</th>
                            <th scope="col">Study Expenses in Australia.</th>
                            <th scope="col">Cost</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                            <th scope="row">1</th>
                            <td>Vocational Education and Training</td>
                            <td>$4,000 to $22,000</td>
                            </tr>
                            <tr>
                            <th scope="row">2</th>
                            <td>Undergraduate Bachelor Degree</td>
                            <td>$20,000 to $45,000</td>
                            </tr>
                            <tr>
                            <th scope="row">3</th>
                            <td>Postgraduate Masters Degree</td>
                            <td>$22,000 to $50,000</td>
                            </tr>
                            <tr>
                            <th scope="row">3</th>
                            <td>Doctoral Degree</td>
                            <td>$18,000 to $42,000</td>
                            </tr>
                        </tbody>
                        </table>
                        <table className="table table-striped table-bordered mx-3">
                        <thead className="global-primary">
                            <tr>
                            <th scope="col">S.N</th>
                            <th scope="col">Accommodation Expenses in Japan.</th>
                            <th scope="col">Cost</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                            <th scope="row">1</th>
                            <td>Hostels and Guesthouses -</td>
                            <td>$90 to $150 per week</td>
                            </tr>
                            <tr>
                            <th scope="row">2</th>
                            <td>Shared Rental </td>
                            <td>$95 to $215 per week</td>
                            </tr>
                        </tbody>
                        </table>
                        <table className="table table-striped table-bordered mx-3">
                        <thead className="global-primary">
                            <tr>
                            <th scope="col">S.N</th>
                            <th scope="col">Living Expenses in Japan.</th>
                            <th scope="col">Cost</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                            <th scope="row">1</th>
                            <td>Groceries and eating out </td>
                            <td>$140 to $280 per week</td>
                            </tr>
                            <tr>
                            <th scope="row">2</th>
                            <td>Gas, electricity</td>
                            <td>$10 to $20 per week</td>
                            </tr>
                            <tr>
                            <th scope="row">3</th>
                            <td>Phone and Internet </td>
                            <td>$15 to $30 per week</td>
                            </tr>
                            <tr>
                            <th scope="row">3</th>
                            <td>Public transport</td>
                            <td>$30 to $60 per week</td>
                            </tr>
                        </tbody>
                        </table> */}
                    </div>
                
                </div>

                </section>
          </>
        );
      }
    }
  }