import React from "react";
import usa from "../../assests/images/usa.jpg";
import aboutone from "../../assests/images/aboutone.png";
import balaram from "../../assests/employee/balaram.jpg";
import bimala from "../../assests/employee/bimala.jpg";
import chandra from "../../assests/employee/chandra.jpg";
import dristee from "../../assests/employee/dristee.jpg";
import hemraj from "../../assests/employee/hemraj.jpg";
import krish from "../../assests/employee/krish.jpg";
import krishna from "../../assests/employee/krishna.jpg";
import palden from "../../assests/employee/palden.jpg";
import prakash from "../../assests/employee/prakash.jpg";
import pritam from "../../assests/employee/pritam.jpg";
import sagar from "../../assests/employee/sagar.jpg";
import sumitra from "../../assests/employee/sumitra.jpg";
import prabesh from "../../assests/employee/prabesh.jpeg";
// import blobanimation from "../../assests/images/blobanimation.svg";
import "./about.css";
import data from "../../data.json"
import Abouts from "../../hooks/Abouts";
import Team from "../../hooks/Team";

const AboutUs = () => {
  return (
    <section>
    <div className="cover-bg">
      <div className="container py-5">
      <h1 className="fw-bold text-center">About Us.</h1>
      <p className="fw-bold fs-5 text-center">Discover Our Story: Who We Are and What We Stand For.</p>
      <p className="text-center lh-base">Established in 2009, GLOBAL MOON CONSULTANCY PVT. LTD. has already made a
        striking imprint in the field of education consultation serving hundreds of students for
        different rewarding academic destinations hitherto. We have been recognized for our
        flawless counseling services to satisfy and make successful the pursuance of students
        in desired profound destinations varying from Australia, USA, Canada, Japan, New
        Zealand and major European countries (Denmark, UK, Spain, Italy and Norway).
      </p>
      </div>
      <img src={usa} className='banner-img' alt="usa"></img>
    </div>
    <div className="container">  
        <Abouts />
      <div className="ourteam">
        <h1 className="fw-bold text-center">Meet our Team.</h1>
        <p className="fw-bold fs-5 text-center">Get insight on our team.</p>
        <div className='parent py-5 my-5'>
          <Team />
            {/* <div className='child'>
              <img className="tlogo" src={balaram} alt="planning"/>
              <p className="team-name m-0 lh-base fw-bold fs-5">Balaram Lamichhane</p>
              <p className="team-role m-0 lh-base">Manager</p>
            </div>
            <div className='child'>
              <img className="tlogo" src={hemraj} alt="planning"/>
              <p className="team-name m-0 lh-base fw-bold fs-5">Hemraj Sharma</p>
              <p className="team-role m-0 lh-base">Canada Facilitator</p>
            </div>
            <div className='child'>
              <img className="tlogo" src={chandra} alt="planning"/>
              <p className="team-name m-0 lh-base fw-bold fs-5">Chandra Datta Sharam</p>
              <p className="team-role m-0 lh-base">Korea Facilitator</p>
            </div>

            <div className='child'>
              <img className="tlogo" src={prakash} alt="planning"/>
              <p className="team-name m-0 lh-base fw-bold fs-5">Prakash paudel</p>
              <p className="team-role m-0 lh-base">USA Facilitator</p>
            </div>
            <div className='child'>
              <img className="tlogo" src={krish} alt="planning"/>
              <p className="team-name m-0 lh-base fw-bold fs-5">Krish Lamichhane</p>
              <p className="team-role m-0 lh-base">Japan Facilitator</p>
            </div>
            <div className='child'>
              <img className="tlogo" src={sagar} alt="planning"/>
              <p className="team-name m-0 lh-base fw-bold fs-5">Sagar Acharya</p>
              <p className="team-role m-0 lh-base">USA Facilitator</p>
            </div>
            <div className='child'>
              <img className="tlogo" src={aboutone} alt="planning"/>
              <p className="team-name m-0 lh-base fw-bold fs-5">Uttam Pandit</p>
              <p className="team-role m-0 lh-base">Japan Facilitator</p>
            </div>
            <div className='child'>
              <img className="tlogo" src={pritam} alt="planning"/>
              <p className="team-name m-0 lh-base fw-bold fs-5">Pritam Khadka</p>
              <p className="team-role m-0 lh-base">Australia Facilitator</p>
            </div>
            <div className='child'>
              <img className="tlogo" src={palden} alt="planning"/>
              <p className="team-name m-0 lh-base fw-bold fs-5">Palden Sensei</p>
              <p className="team-role m-0 lh-base">Japan Facilitator</p>
            </div>
            <div className='child'>
              <img className="tlogo" src={bimala} alt="planning"/>
              <p className="team-name m-0 lh-base fw-bold fs-5">Bimala Devi Sharma</p>
              <p className="team-role m-0 lh-base">Administrator</p>
            </div>
            <div className='child'>
              <img className="tlogo" src={dristee} alt="planning"/>
              <p className="team-name m-0 lh-base fw-bold fs-5">Dristee Shinkhada</p>
              <p className="team-role m-0 lh-base">Canada Facilitator</p>
            </div>
            <div className='child'>
              <img className="tlogo" src={aboutone} alt="planning"/>
              <p className="team-name m-0 lh-base fw-bold fs-5">Raghu Bhandari</p>
              <p className="team-role m-0 lh-base">Country Representative</p>
            </div>
            <div className='child'>
              <img className="tlogo" src={aboutone} alt="planning"/>
              <p className="team-name m-0 lh-base fw-bold fs-5">Devraj Acharya</p>
              <p className="team-role m-0 lh-base">New Zealand Head</p>
            </div>
            <div className='child'>
              <img className="tlogo" src={prabesh} alt="planning"/>
              <p className="team-name m-0 lh-base fw-bold fs-5">Prabesh Paudel</p>
              <p className="team-role m-0 lh-base">Canada Facilitator</p>
            </div>
            <div className='child'>
              <img className="tlogo" src={sumitra} alt="planning"/>
              <p className="team-name m-0 lh-base fw-bold fs-5">Sumitra Maraseni</p>
              <p className="team-role m-0 lh-base">Receptionist</p>
            </div> */}
          </div>
      </div>
    </div>

    </section>
  );
};

export default AboutUs;
