import React from "react";
import CircularProgress from '@mui/material/CircularProgress';
import { Link } from "react-router-dom";
import Logo from "../assests/images/Logo.png";



export default class BannerModel extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        error: null,
        isLoaded: false,
        bannermodel: []
      };
    }
    componentDidMount() {
      fetch("https://admin.globalmoon.edu.np/api/bannermodel")
        .then(res => res.json())
        .then(
          (result) => {
            this.setState({
              isLoaded: true,
              bannermodel: result
            });
          },
          (error) => {
            this.setState({
              isLoaded: true,
              error
            });
          }
        )
    }
  
    render() {
      const { error, isLoaded, bannermodel } = this.state;
      if (error) {
        return <div>Error: {error.message}</div>;
      } else if (!isLoaded) {
        return (
          <div className="loading-holder-modal placeholder-wave">
            <CircularProgress />
          </div>
        );
      } else {
        return (
            <>
              {Object.keys(bannermodel).length === 0 ? (
                <img src={Logo} width="300px" className="p-5"/>
              ) : (
                <>
                  <Link to={bannermodel[0].modalbanner_link}>
                    <img src={'https://admin.globalmoon.edu.np/modalbanner/' + bannermodel[0].modalbanner_image} style={{ width: '100%', height: 'auto' }} />
                  </Link>
                </>
              )}
            </>
        );
      }
    }
  }