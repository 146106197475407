import React from "react";
import CircularProgress from '@mui/material/CircularProgress';

export default class Abouts extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        error: null,
        isLoaded: false,
        abouts: []
      };
    }
    componentDidMount() {
      fetch("https://admin.globalmoon.edu.np/api/abouts")
        .then(res => res.json())
        .then(
          (result) => {
            this.setState({
              isLoaded: true,
              abouts: result
            });
          },
          (error) => {
            this.setState({
              isLoaded: true,
              error
            });
          }
        )
    }
  
    render() {
      const { error, isLoaded, abouts } = this.state;
      if (error) {
        return <div>Error: {error.message}</div>;
      } else if (!isLoaded) {
        return (
          <div className="loading-holder">
            <CircularProgress />
          </div>
        );
      } else {
        return (
          <ul>
            {abouts.map((item,index) => (
              index % 2 !== 0 ?
                <div className="row py-5 my-5 d-flex align-items-center">
                  <div className="col-md-6 col-sm-12 relative text-center">
                    <img className="side-img" src={'https://admin.globalmoon.edu.np/abouts/'+item.about_image} alt="usa"></img>
                  </div>
                  <div className="col-md-6 col-sm-12">
                  <h1 fw-bold>{item.heading}</h1>
                  <p className="lh-base">{item.content}</p>
                  </div>
                </div> :          
                <div className="row py-5 my-5 d-flex align-items-center">
                  <div className="col-md-6 col-sm-12">
                  <h1 fw-bold>{item.heading}</h1>
                  <p className="lh-base">{item.content}</p>
                  </div>
                  <div className="col-md-6 col-sm-12 relative text-center">
                    <img className="side-img" src={'https://admin.globalmoon.edu.np/abouts/'+item.about_image} alt="usa"></img>
                  </div>
                </div>
            ))}
          </ul>
        );
      }
    }
  }