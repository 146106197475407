import React from "react";
import { Link } from "react-router-dom";
import CircularProgress from '@mui/material/CircularProgress';

export default class GalleryHook extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        error: null,
        isLoaded: false,
        gallery: []
      };
    }
    componentDidMount() {
      fetch("https://admin.globalmoon.edu.np/api/gallery")
        .then(res => res.json())
        .then(
          (result) => {
            this.setState({
              isLoaded: true,
              gallery: result
            });
          },
          (error) => {
            this.setState({
              isLoaded: true,
              error
            });
          }
        )
    }
  
    render() {
      const { error, isLoaded, gallery } = this.state;
      if (error) {
        return <div>Error: {error.message}</div>;
      } else if (!isLoaded) {
        return (
          <div className="loading-holder">
            <CircularProgress />
          </div>
        );
      } else {
        return (
            <>
            {gallery.map((blog,i)=>(
                <div className="col-lg-3 col-md-4 col-6" key={i}>
                    <Link to={`../gallery/${blog.event_name}`}>
                    <div className="img-card">
                        <img src={'https://admin.globalmoon.edu.np/galleryevent/'+blog.event_cover} />
                        <div className="img-card-text px-4">
                        <h5 className="mb-4">{blog.event_name}</h5>
                        </div>
                    </div>
                    </Link>
                </div>
              ))}
            </>
        );
      }
    }
  }