
import Header from "../components/Header/Header"
import Footer from "../components/Footer/Footer";
import { useParams } from "react-router-dom";
import usa from "../assests/images/usa.jpg";
import data from "../data.json";
import BlogPageHook from "../hooks/BlogPageHook.js";


import { Fragment } from "react";
const BlogPage = () => {
    const { link } = useParams();

  return (
    <>
    <div className="gal-page">
      <BlogPageHook link={link} />
    </div>
    </>
  );
};

export default BlogPage;
