import React from "react";
import CircularProgress from '@mui/material/CircularProgress';
import { Link } from "react-router-dom";

import Carousel from 'react-bootstrap/Carousel';

export default class Banner extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        error: null,
        isLoaded: false,
        banner: []
      };
    }
    componentDidMount() {
      fetch("https://admin.globalmoon.edu.np/api/banner")
        .then(res => res.json())
        .then(
          (result) => {
            this.setState({
              isLoaded: true,
              banner: result
            });
          },
          (error) => {
            this.setState({
              isLoaded: true,
              error
            });
          }
        )
    }
  
    render() {
      const { error, isLoaded, banner } = this.state;
      if (error) {
        return <div>Error: {error.message}</div>;
      } else if (!isLoaded) {
        return (
          <div className="loading-holder">
            <CircularProgress />
          </div>
        );
      } else {
        return (
            <>
              <Carousel>
                {banner.map((blog,i)=>(
                    <Carousel.Item key={i}>
                    <Link to={blog.banner_link} >
                    <img
                        className="d-block w-100 slider-img"
                        src={'https://admin.globalmoon.edu.np/banner/'+blog.banner_image}
                        alt={blog.banner_description}
                    />
                    </Link>
                    </Carousel.Item>
                ))}
              </Carousel>
            </>
        );
      }
    }
  }