import React from "react";
import CircularProgress from '@mui/material/CircularProgress';

export default class Team extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        error: null,
        isLoaded: false,
        team: []
      };
    }
    componentDidMount() {
      fetch("https://admin.globalmoon.edu.np/api/team")
        .then(res => res.json())
        .then(
          (result) => {
            this.setState({
              isLoaded: true,
              team: result
            });
          },
          (error) => {
            this.setState({
              isLoaded: true,
              error
            });
          }
        )
    }
  
    render() {
      const { error, isLoaded, team } = this.state;
      if (error) {
        return <div>Error: {error.message}</div>;
      } else if (!isLoaded) {
        return (
          <div className="loading-holder">
            <CircularProgress />
          </div>
        );
      } else {
        return (
          <>
        {team.map((team, index) => (
            <div className='child' key={index}>
              <img className="tlogo" src={'https://admin.globalmoon.edu.np/teammember/'+team.member_image}/>
              {/* src={team.photo} */}
              <p className="team-name m-0 lh-base fw-bold fs-5">{team.member_name}</p>
              <p className="team-role m-0 lh-base">{team.member_post}</p>
            </div>
        ))}
          </>
        );
      }
    }
  }