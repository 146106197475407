import { useFormik } from "formik";
import * as yup from "yup";
import { toast } from "react-toastify";
import http from '../http';
import { useNavigate } from "react-router-dom";



const schema = yup.object().shape({
  name: yup.string().required("Name is required"),
  email: yup
    .string()
    .required("Email is required")
    .email("Invalid email format"),
  number: yup
    .string()
    .required("Number is required")
    .min(10, "Contact number can only have 10 digits")
    .max(10, "Contact number can only have 10 digits"),
  help: yup.string().required("Please select one option."),
  feedback:yup.string().required("Question is required.")
});

const useEmailContact = () => {
  const initialValues = {
    name: "",
    email: "",
    number: "",
    help: "",
    feedback:"",
  };
  const formik = useFormik({
    initialValues,
    validationSchema: schema,
    onSubmit: (values, helpers) => {
      helpers.setSubmitting(true);
      http.post('/submitform', values).then((res) => {
          helpers.setSubmitting(false);
          if (res.status === 200) {
            helpers.resetForm();
            toast.success("Your message has been sent", {
              position: "bottom-right",
            });
          }
        })
        .catch((err) => {
          helpers.setSubmitting(false);
          toast.error("Something went wrong please try again.", {
            position: "bottom-right",
          });
        });
    },
  });

  return { formik };
};

export default useEmailContact;
