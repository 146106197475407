import React from "react";

import { Link } from "react-router-dom";
import BannerModel from "../../hooks/Banner";



const HeroSection = () => {
  return (
    <section className="relative">
      <BannerModel />
      <div className="four-con">
      <div className="container-custom">
        <div className="row">
          <div className="col-md-3 col-6">
            <Link to="/studyabroad/studyinjapan" className="hoov">
            <div className="abs-card">
              <div className="cicle">
                <img src="https://cdn-icons-png.flaticon.com/128/197/197604.png" className="scale-2"/>
              </div>
              <h4 className="fw-bold text-center relative">Study In <br></br>Japan</h4>
            </div>
            </Link>
          </div>
          <div className="col-md-3 col-6">
            <Link to="/studyabroad/canada" className="hoov">
              <div className="abs-card">
                <div className="cicle">
                  <img src="https://cdn-icons-png.flaticon.com/128/197/197430.png"/>
                </div>
                <h4 className="fw-bold text-center relative">Study In <br></br>Canada</h4>
              </div>
            </Link>
          </div>
          <div className="col-md-3 col-6">
            <Link to="/studyabroad/australia" className="hoov">
            <div className="abs-card">
              <div className="cicle">
                <img src="https://cdn-icons-png.flaticon.com/128/323/323367.png"/>
              </div>
              <h4 className="fw-bold text-center relative">Study In <br></br>Australia</h4>
            </div>
            </Link>
          </div>
          <div className="col-md-3 col-6">
            <Link to="/studyabroad/uk" className="hoov">
            <div className="abs-card">
              <div className="cicle">
                <img src="https://cdn-icons-png.flaticon.com/128/197/197374.png"/>
              </div>
              <h4 className="fw-bold text-center relative">Study In <br></br>UK</h4>
            </div>
            </Link>
          </div>
        </div>
      </div>
      </div>
    </section>
  );
};

export default HeroSection;
      