import React from "react";
import { Container, Row } from "reactstrap";
import "./courses.css";
// import stroke from "../../assests/images/stroke.jpg";
import { Link } from "react-router-dom";
import data from "../../data.json";
import TestPreparationCard from "../../hooks/TestPreparationCard";

const Courses = () => {
  return (
    <section className="py-5" id="test">
        <Container>
        <h1 className="fw-bold">Test Preparations we provide.</h1>
        <p className="fw-bold fs-5">Start your overseas education journey with the best available courses.</p>
            <Row className="d-flex align-items-center pt-4">
              <TestPreparationCard />
            </Row>
        </Container>
    </section>
  );
};

export default Courses;

