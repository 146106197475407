import React, { Fragment } from "react";
import Header from "../components/Header/Header"
import Footer from "../components/Footer/Footer";
import usa from "../assests/images/usa.jpg";
import data from "../data.json";
import { Link } from "react-router-dom";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
const tf ={
    width:'45%',
    margin:'10px',
    background:'white',
    "&:active": {
      border:"none",
    },
    borderColor:'white',
    borderRadius:'10px'
  };

const btn = {
  width: "95%",
  margin: "10px",
  background: "#f37224",
  "&:active": {
    border: "white",
  },
  "&:hover": {
    background: "#CF6120",
  },
};
const box = {
  padding: "10px",
  background: "#f0f0f0",
  borderRadius: "20px",
};

const Enroll = () => {
  const [age, setAge] = React.useState('');

  const handleChange = (event) => {
    setAge(event.target.value);
  };
  return (
    <Fragment>
      <Header />
        <section>   
            <div className="cover-bg">
            <div className="container py-5">
            <h1 className="fw-bold text-center">Home / Enroll</h1>
            <p className="fw-bold fs-5 text-center">Get Enrolled Online.</p>
            </div>
            <img src={usa} className='banner-img' alt="usa"></img>
            </div>
        </section>
        <section>
            <div className="container py-5">
            <Box
                    component="form"
                    sx={box}
                    >
                    <h3 className="p-3 mb-0 fs-4">Please fill the below form.</h3>
                    <TextField id="outlined-basic" label="Applicant Name" variant="outlined" sx={tf} />
                    <TextField id="outlined-basic" label="Applicant ID Number" variant="outlined" sx={tf} />
                    <TextField id="outlined-basic" label="Your Number" variant="outlined" sx={tf} />
                    <TextField id="outlined-basic" label="Applicant Contact Number" variant="outlined" sx={tf} />
                    <TextField id="outlined-basic" label="Applicant DOB " variant="outlined" sx={tf} />
                    <TextField id="outlined-basic" label="Complete Academic " variant="outlined" sx={tf} />
                    <TextField id="outlined-basic" label="Complete Pass Year " variant="outlined" sx={tf} />
                    <TextField id="outlined-basic" label="Running " variant="outlined" sx={tf} />
                    <TextField id="outlined-basic" label="Working " variant="outlined" sx={tf} />
                    <TextField id="outlined-basic" label="GPA/CGPA/Percentage " variant="outlined" sx={tf} />
                    <TextField id="outlined-basic" label="Full Address" variant="outlined" sx={tf} />
                    <TextField id="outlined-basic" label="Father Name" variant="outlined" sx={tf} />
                    <TextField id="outlined-basic" label="Mother Name" variant="outlined" sx={tf} />
                    <TextField id="outlined-basic" label="Grand Father Name" variant="outlined" sx={tf} />
                    <TextField id="outlined-basic" label="E-mail Address" variant="outlined" sx={tf} />
                    <TextField id="outlined-basic" label="Sponsor Contact Number" variant="outlined" sx={tf} />
                    
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Select Country</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={age}
                            label="Age"
                            onChange={handleChange}
                            sx={tf}
                        >
                            <MenuItem value={10}>USA</MenuItem>
                            <MenuItem value={20}>Japan</MenuItem>
                            <MenuItem value={30}>America</MenuItem>
                            <MenuItem value={30}>Denmark</MenuItem>
                        </Select>
                    </FormControl>
                    <Button
                        component="label"
                        variant="contained"
                        sx={btn}
                    >Submit</Button>
                    </Box>
            </div>
        </section>
      <Footer />
    </Fragment>
  );
};

export default Enroll;
